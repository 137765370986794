import mitt from "mitt";
import {
    EmitterDefaultLayoutScrollLayoutArgs,
    QuizBoardAnswerValidEventParams
} from "../types/emitter";

type Events = {
    "Dialog::close-dialog": boolean;

    "SideMenu::close-side-menu": boolean;

    "DefaultLayout::scrollLayout": EmitterDefaultLayoutScrollLayoutArgs;
    "DefaultLayout::showBottomNavigation": void;
    "DefaultLayout::hideBottomNavigation": void;

    "QuizBoard::answer-valid": QuizBoardAnswerValidEventParams;
    "QuizBoard::answer-invalid": string;
    "QuizBoard::override-next-button-text": string;
    "QuizBoard::override-next-button-disabled-state": boolean;
    "QuizBoard::override-next-button-visibility-state": boolean;
    "QuizBoard::override-scrolldown-button-visibility-state": boolean;

    "QuizContentBlock::asl-entered": void;
};

export const emitter = mitt<Events>();
