<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="computedSize"
        :height="computedSize"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        :aria-labelledby="iconComponent"
        role="presentation"
        class="custom-icon"
        :class="class"
    >
        <g :fill="computedColor">
            <component :is="iconComponent" />
        </g>
    </svg>
    <div
        v-if="indicator && indicatorValue != '0'"
        class="custom-icon--indicator"
        :data-indicator-value="indicatorValue"
    >
        <span class="indicator-value">{{
            typeof indicatorValue === "number" && indicatorValue > 9 ? "9+" : indicatorValue
        }}</span>
    </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, defineComponent } from "vue";
import { isValidCssColor } from "../../helpers/isValidCssColor";
import { colors } from "../../constants";

import ArrowDownIcon from "../icons/ArrowDownIcon.vue";
import ArrowLeftIcon from "../icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "../icons/ArrowRightIcon.vue";
import BellIcon from "../icons/BellIcon.vue";
import CalendarIcon from "../icons/CalendarIcon.vue";
import CalendarFilledIcon from "../icons/CalendarFilledIcon.vue";
import CartIcon from "../icons/CartIcon.vue";
import ChatIcon from "../icons/ChatIcon.vue";
import ChatFilledIcon from "../icons/ChatFilledIcon.vue";
import CheckIcon from "../icons/CheckIcon.vue";
import ChevronDownIcon from "../icons/ChevronDownIcon.vue";
import ChevronDownCircleIcon from "../icons/ChevronDownCircleIcon.vue";
import ChevronLeftIcon from "../icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../icons/ChevronRightIcon.vue";
import ChevronUpIcon from "../icons/ChevronUpIcon.vue";
import ClockIcon from "../icons/ClockIcon.vue";
import CloseIcon from "../icons/CloseIcon.vue";
import CogIcon from "../icons/CogIcon.vue";
import DeclineIcon from "../icons/DeclineIcon.vue";
import DeclineCircleIcon from "../icons/DeclineCircleIcon.vue";
import EmojiIcon from "../icons/EmojiIcon.vue";
import FilterIcon from "../icons/FilterIcon.vue";
import GoogleIcon from "../icons/GoogleIcon.vue";
import HomeIcon from "../icons/HomeIcon.vue";
import InfoCircleIcon from "../icons/InfoCircleIcon.vue";
import LocationIcon from "../icons/LocationIcon.vue";
import LockIcon from "../icons/LockIcon.vue";
import MenuBurgerIcon from "../icons/MenuBurgerIcon.vue";
import MenuDotsVerticalIcon from "../icons/MenuDotsVerticalIcon.vue";
import MicIcon from "../icons/MicIcon.vue";
import PencilIcon from "../icons/PencilIcon.vue";
import PhoneIcon from "../icons/PhoneIcon.vue";
import PhoneDownIcon from "../icons/PhoneDownIcon.vue";
import PlusIcon from "../icons/PlusIcon.vue";
import RecordIcon from "../icons/RecordIcon.vue";
import RecordOutlinedIcon from "../icons/RecordOutlinedIcon.vue";
import SendIcon from "../icons/SendIcon.vue";
import StarIcon from "../icons/StarIcon.vue";
import StarFilledIcon from "../icons/StarFilledIcon.vue";

type IconComponents = {
    [key: string]: ReturnType<typeof defineComponent>;
};

const iconComponents: IconComponents = {
    arrowDown: ArrowDownIcon,
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    bell: BellIcon,
    calendar: CalendarIcon,
    calendarFilled: CalendarFilledIcon,
    cart: CartIcon,
    chat: ChatIcon,
    chatFilled: ChatFilledIcon,
    check: CheckIcon,
    chevronDown: ChevronDownIcon,
    chevronDownCircle: ChevronDownCircleIcon,
    chevronLeft: ChevronLeftIcon,
    chevronRight: ChevronRightIcon,
    chevronUp: ChevronUpIcon,
    clock: ClockIcon,
    close: CloseIcon,
    cog: CogIcon,
    decline: DeclineIcon,
    declineCircle: DeclineCircleIcon,
    emoji: EmojiIcon,
    filter: FilterIcon,
    google: GoogleIcon,
    home: HomeIcon,
    infoCircle: InfoCircleIcon,
    location: LocationIcon,
    lock: LockIcon,
    menuBurger: MenuBurgerIcon,
    menuDotsVertical: MenuDotsVerticalIcon,
    mic: MicIcon,
    pencil: PencilIcon,
    phone: PhoneIcon,
    phoneDown: PhoneDownIcon,
    plus: PlusIcon,
    record: RecordIcon,
    recordOutlined: RecordOutlinedIcon,
    send: SendIcon,
    star: StarIcon,
    starFilled: StarFilledIcon
};

const props = defineProps({
    size: {
        type: [String, Number],
        default: 24
    },
    name: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: "currentColor"
    },
    indicator: {
        type: Boolean,
        default: false
    },
    indicatorValue: {
        type: [String, Number],
        default: ""
    },
    class: {
        type: String,
        default: ""
    }
});

const computedSize: ComputedRef<number> = computed(() => {
    return parseInt(String(props.size));
});
const computedColor: ComputedRef<string> = computed(() => {
    if (isValidCssColor(props.color)) return props.color;
    const c = colors[props.color];
    if (c) return c;
    return "currentColor";
});

const iconComponent = computed(() => {
    const iconName = props.name.replace(/-./g, x => x[1].toUpperCase());
    return iconComponents[iconName];
});
</script>
