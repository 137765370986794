<template>
    <div id="app">
        <DefaultLayout>
            <router-view v-slot="{ Component }">
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <component
                        :is="Component"
                        :key="route_key"
                    />
                </transition>
            </router-view>
        </DefaultLayout>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const route_key = computed(() => {
    if (route.name === "quiz" || route.name === "quiz-board") return "quiz";
    return route.fullPath;
});
</script>
