<template>
    <div class="alerts">
        <div class="alerts__wrapper">
            <transition-group name="alerts" tag="div">
                <div
                    v-for="entry in alerts"
                    :key="entry.id"
                    class="alerts-item"
                    :class="{
                        'alerts-item--success': entry.type == AlertType.success,
                        'alerts-item--error': entry.type == AlertType.error,
                        'alerts-item--info': entry.type == AlertType.info
                    }"
                >
                    {{ entry.msg }}
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { AlertType } from "../../types/alert";
import { useGeneralStore } from "../../stores/general";

const generalStore = useGeneralStore();
const { alerts } = storeToRefs(generalStore);
</script>
