<template>
    <div class="bottom-navigation">
        <router-link
            v-for="item in navigation_items"
            :key="item.route"
            to="{ name: item.route }"
            :class="{
                'bottom-navigation__button': true,
                'bottom-navigation__button--active': route.name === item.route
            }"
        >
            <CustomIcon
                color="primary_dark"
                :name="item.icon"
            />
            <span class="bottom-navigation__button-label">{{ item.label }}</span>
        </router-link>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";

import CustomIcon from "../../components/generics/CustomIcon.vue";

interface NavigationItem {
    icon: string;
    label: string;
    route: string;
}

/*###############
### VARIABLES ###
############## */
const route = useRoute();
const navigation_items = ref<NavigationItem[]>([
    {
        icon: "home",
        label: "pulpit",
        route: "home"
    },
    {
        icon: "calendar",
        label: "kalendarz",
        route: "calendar"
    },
    {
        icon: "chat",
        label: "chat",
        route: "chat"
    },
    {
        icon: "cog",
        label: "ustawienia",
        route: "settings"
    }
]);
</script>
