import { defineStore } from "pinia";
import { ref } from "vue";
import { Alert } from "../types/alert";
import { ILazyLoadInstance } from "vanilla-lazyload";
import { RouteRecordName } from "vue-router";

export const useGeneralStore = defineStore("general", () => {
    /*###################
    ### ENV VARIABLES ###
    ###################*/
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    const SELF_BASE_URL = import.meta.env.VITE_SELF_BASE_URL;
    const API_BASE_PATH = `${API_BASE_URL}${import.meta.env.VITE_API_BASE_PATH}`;

    /*#########################
    ### MAIN LAYOUT HELPERS ###
    #########################*/
    const main_layout_last_scroll_position = ref<number>(0);
    function getMainLayoutLastScrollPosition() {
        return main_layout_last_scroll_position.value;
    }
    function setMainLayoutLastScrollPosition(v: number) {
        main_layout_last_scroll_position.value = v;
    }

    const main_layout_last_scroll_height = ref<number>(0);
    function getMainLayoutLastScrollHeight() {
        return main_layout_last_scroll_height.value;
    }
    function setMainLayoutLastScrollHeight(v: number) {
        main_layout_last_scroll_height.value = v;
    }

    const main_layout_last_offset_height = ref<number>(0);
    function getMainLayoutLastOffsetHeight() {
        return main_layout_last_offset_height.value;
    }
    function setMainLayoutLastOffsetHeight(v: number) {
        main_layout_last_offset_height.value = v;
    }

    /*###################
    ### LAYOUT ALERTS ###
    ###################*/
    const alerts = ref<Alert[]>([]);
    const displayAlert = (alert: Alert) => {
        const AID = `alert_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
        alerts.value.push({
            ...alert,
            id: AID
        });

        if (!alert.duration || alert.duration != -1) {
            setTimeout(
                () => {
                    removeAlert(AID);
                },
                alert.duration && alert.duration > 0 ? alert.duration : 3500
            );
        }
    };
    const removeAlert = (id: string) => {
        const ix = alerts.value.findIndex(it => it.id == id);
        if (ix !== -1) {
            alerts.value.splice(ix, 1);
        }
    };

    /*####################
    ### IMG LAZYLOADER ###
    ####################*/
    const image_lazyloader_instance = ref<null | ILazyLoadInstance>(null);
    function setImageLazyloaderInstance(d: null | ILazyLoadInstance) {
        image_lazyloader_instance.value = d;
    }

    /*##########
    ## OTHERS ##
    ##########*/
    const app_is_focused = ref<boolean>(true);
    function setAppIsFocused(state: boolean) {
        app_is_focused.value = state;
    }

    const navigation_last_route_name = ref<RouteRecordName | null>(null);
    function setNavigationLastRouteName(route_name: RouteRecordName) {
        navigation_last_route_name.value = route_name;
    }

    const is_online = ref<boolean>(true);
    function setIsOnline(state: boolean) {
        is_online.value = state;
    }

    return {
        API_BASE_URL,
        API_BASE_PATH,
        SELF_BASE_URL,

        alerts,
        displayAlert,

        main_layout_last_scroll_position,
        main_layout_last_scroll_height,
        main_layout_last_offset_height,
        getMainLayoutLastScrollPosition,
        setMainLayoutLastScrollPosition,
        getMainLayoutLastScrollHeight,
        setMainLayoutLastScrollHeight,
        getMainLayoutLastOffsetHeight,
        setMainLayoutLastOffsetHeight,

        is_online,
        setIsOnline,

        app_is_focused,
        setAppIsFocused,

        navigation_last_route_name,
        setNavigationLastRouteName,

        image_lazyloader_instance,
        setImageLazyloaderInstance
    };
});
