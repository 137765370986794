<template>
    <div class="default-layout">
        <Onboarding v-if="false" />
        <Alerts />
        <div
            class="default-layout-content"
            ref="layout_content"
        >
            <slot />
        </div>
        <BottomNavigation v-if="show_bottom_navigation" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useGeneralStore } from "../stores/general";
import { emitter } from "../helpers/eventEmitter";
import BottomNavigation from "../components/Navigation/BottomNavigation.vue";
import Onboarding from "../components/Onboarding/Onboarding.vue";
import Alerts from "../components/generics/Alerts.vue";
import { EmitterDefaultLayoutScrollLayoutArgs } from "../types/emitter";

/*###############
### VARIABLES ###
############## */
const generalStore = useGeneralStore();
const layout_content = ref<HTMLElement | null>(null);

const route = useRoute();
const bottom_navigation_hidden_manually = ref(false);

const HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES = ["quiz", "offline", "e404"];

/*##############
### COMPUTED ###
############# */
const show_bottom_navigation = computed(() => {
    // TODO: potem trzeba to poprawić, aby BottonNavigation się pojawiało
    return (
        false &&
        !bottom_navigation_hidden_manually.value &&
        !HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES.includes(route.name as string)
    );
});

/*#############
### METHODS ###
############ */
function handleLayoutScroll() {
    generalStore.setMainLayoutLastScrollPosition(layout_content.value?.scrollTop || 0);
    generalStore.setMainLayoutLastScrollHeight(layout_content.value?.scrollHeight || 0);
    generalStore.setMainLayoutLastOffsetHeight(layout_content.value?.offsetHeight || 0);
}

function scrollLayout(y: number, animate: boolean = false) {
    layout_content.value?.scrollTo({
        left: 0,
        top: y,
        behavior: animate ? "smooth" : "instant"
    });
}

function hideBottomNavigation() {
    bottom_navigation_hidden_manually.value = true;
}
function showBottomNavigation() {
    bottom_navigation_hidden_manually.value = false;
}

function onEmitterScrollLayout(args: EmitterDefaultLayoutScrollLayoutArgs) {
    scrollLayout(args.y, args.animate);
}

/*###########
### HOOKS ###
########## */
onMounted(() => {
    generalStore.setMainLayoutLastScrollPosition(0);

    emitter.on("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.on("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.on("DefaultLayout::showBottomNavigation", showBottomNavigation);

    layout_content.value?.addEventListener("scroll", handleLayoutScroll, {
        passive: true
    });
});

onUnmounted(() => {
    layout_content.value?.removeEventListener("scroll", handleLayoutScroll);
    emitter.off("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.off("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.off("DefaultLayout::showBottomNavigation", showBottomNavigation);
});

defineExpose({
    scrollLayout,
    hideBottomNavigation,
    showBottomNavigation
});
</script>
