export enum AlertType {
    success = "success",
    error = "error",
    info = "info"
}

export type Alert = {
    type: AlertType;
    msg: string;
    id?: string;
    duration?: number;
};